import Decimal from "decimal.js";
import { JobStatusesMap } from "helpers/constants/index";
import _, { find } from "lodash";
import * as pagination from "utils/graphql-pagination";
import { isDateBigger } from "utils/time";
import { paginationSettings } from "./constants";
import moment from "moment";

export const getAllWorkersPaginationData = state => {
  return state.jobDetails[pagination.getPageInfoAttr(paginationSettings.allWorkers)];
};

export const getPreferredWorkersPaginationData = state => {
  return state.jobDetails[pagination.getPageInfoAttr(paginationSettings.preferredWorkers)];
};

export const getWorkerListData = (state, { list, part }) => {
  return state.jobDetails.workerPickers[list][part];
};

export const getJobDetails = state => {
  return state.jobDetails;
};

// Loading selectors
export const getLoadingStateFactory = loader => state => {
  return state.jobDetails.loading[loader] || false;
};

export const getIsScheduledJobLoading = getLoadingStateFactory("scheduledJobLoading");
export const getIsPageLoading = getLoadingStateFactory("page");
export const getIsAddingWorker = getLoadingStateFactory("addingWorker");
export const getIsUpdatingPositions = getLoadingStateFactory("updatingPositions");
export const getIsApplyingAction = getLoadingStateFactory("actions");
export const getIsDeleteJobLoading = getLoadingStateFactory("isDeleteJobLoading");

// Dialog selector
export const getCancelJobDialogState = (state) => {
  return state.jobDetails.isCancelJobDialog;
};

// Position Action States
export const getCanCheckInWorker = (positionId) => state => {
  const status = getJobStatus(state);
  const { startShiftUtc, checkedInAtUtc, checkedOutAtUtc } = getPosition(positionId)(state);
  const canUpdateCheckIn = !checkedOutAtUtc && isDateBigger(checkedInAtUtc);
  return (
    (status === JobStatusesMap.InProgress || status === JobStatusesMap.Completed) &&
    getIsActive(state) &&
    (!startShiftUtc || canUpdateCheckIn)
    // getIsPositionEditable(positionId)(state)
  );
};

export const getCanEditCheckInWorker = (positionId) => state => {
  const { checkedInAtUtc, checkedOutAtUtc } = getPosition(positionId)(state);
  return !checkedOutAtUtc && checkedInAtUtc;
};

export const getCanCheckOutWorker = positionId => state => {
  const status = getJobStatus(state);
  const { startShift, endShiftUtc, checkedOutAtUtc } = getPosition(positionId)(state);
  const canUpdateCheckOut = isDateBigger(checkedOutAtUtc);
  const jobDetails = getJobDetails(state);

  return (
    (status === JobStatusesMap.InProgress || status === JobStatusesMap.Completed) &&
    startShift &&
    getIsActive(state) &&
    getIsPositionEditable(positionId)(state) &&
    moment().diff(moment(jobDetails.endUtc), "hours") < 24 &&
    (!endShiftUtc || (checkedOutAtUtc && canUpdateCheckOut))
  );
};

export const getCanEditCheckOutWorker = (positionId) => state => {
  const { checkedOutAtUtc } = getPosition(positionId)(state);
  return isDateBigger(checkedOutAtUtc)
};


export const getRatingOption = state => {
  return state.jobDetails.ratingOption || [];
};

// Job Data accessors
export const getPositions = state => {
  return state.jobDetails.positions || [];
};

export const getPositionUpdates = state => {
  return state.jobDetails.positionUpdates || [];
};

export const getPreferred = state => {
  return state.jobDetails.preferred || [];
};

export const getBanned = state => {
  return state.jobDetails.banned || {};
};

export const getEmployer = state => {
  return state.jobDetails.employer || {};
};

export const getJobId = state => {
  return state.jobDetails.id;
};

export const getPositionsFilled = state => {
  return state.jobDetails.positionsFilled;
};

export const getPeopleNeeded = state => {
  return state.jobDetails.peopleNeeded;
};

export const getIsActive = state => {
  return state.jobDetails.isActive;
};

export const getJobStatus = state => state.jobDetails.status;

export const getJobStartUtc = state => {
  return state.jobDetails.startUtc;
};

export const getJobEndUtc = state => {
  return state.jobDetails.endUtc;
};
export const getJobTimezone = state => {
  return state.jobDetails.timezone;
};
export const getIsBreakRequiredForJob = state => {
  return state.jobDetails.isBreakRequired;
};

export const getJobBreakMins = state => {
  return state.jobDetails.breakMins || 0;
};

export const getJobPaidBreakMin = state => {
  return state.jobDetails.paidBreakMins
}

export const getJobWorkType = state => {
  return state.jobDetails.workType || {};
};

export const getJobWorkTypeLabel = state => {
  return getJobWorkType(state).label || "";
};

export const getJobDescription = state => {
  return state.jobDetails.description || "";
};
export const getJobUnit = state => {
  return state.jobDetails.unit || "";
};

export const getJobStreet = state => {
  return state.jobDetails.street || "";
};

export const getJobCity = state => {
  return state.jobDetails.city || "";
};

export const getNotifyEmployer = state => {
  return state.jobDetails.notifyEmployer;
};

export const getJobRegion = state => {
  return state.jobDetails.region || "";
};

export const getJobAddress = state => {
  return `${getJobStreet(state)}, ${getJobCity(state)}, ${getJobRegion(state)}, Canada`;
};

export const getEmployerId = state => {
  return state.jobDetails.employerId;
};

export const getJobDurationMins = state => {
  return state.jobDetails.durationMins || 0;
};

export const getJobDurationMinsLessBreak = state => {
  return state.jobDetails.durationMinsLessBreak || 0;
};

export const getRecurringJobList = state => {
  return state.jobDetails.recurringJob || [];
};

export const getJobBreakInfoLabel = state => {
  const breakMins = getJobBreakMins(state);
  const paidBreakMins = getJobPaidBreakMin(state);
  return breakMins === 0 ? { key: "common_labels_paid_break_duration", breakDuration: paidBreakMins } : { key: "common_labels_unpaid_break_duration", breakDuration: breakMins };
};

export const getCompanyName = state => {
  return getEmployer(state).companyName || "";
};

export const getEmployerContact = state => {
  const { firstName, lastName } = getEmployer(state);

  if (!firstName && !lastName) {
    return "";
  }

  return `${firstName || ""}${firstName ? " " : ""}${lastName || ""}`;
};

export const getEmployerEmail = state => {
  return getEmployer(state).email || "";
};

export const getEmployerMobilePhone = state => {
  return getEmployer(state).mobilePhone || "";
};

export const getEmployerRating = state => {
  return getEmployer(state).rating;
};

export const getEmployerJobCount = state => {
  return getEmployer(state).jobCount || 0;
};

export const getEmployerBillingRate = state => {
  return getEmployer(state).billingRate || 0;
};

export const getEmployerPayRate = state => {
  return getEmployer(state).payRate || 0;
};

// Helpers
export const getEstimatedBillableMins = state => {
  const durationMinsLessBreak = getJobDurationMinsLessBreak(state);
  return getPositions(state).reduce(acc => {
    return acc + durationMinsLessBreak;
  }, 0);
};

export const getEstimatedPayable = state => {
  const durationMinsLessBreak = getJobDurationMinsLessBreak(state);
  return getPositions(state).reduce((acc, { payRate }) => {
    return acc + durationMinsLessBreak * (payRate / 60);
  }, 0);
};

export const getEstimatedBillable = state => {
  const durationMinsLessBreak = getJobDurationMinsLessBreak(state);
  return getPositions(state).reduce((acc, { billingRate }) => {
    return acc + durationMinsLessBreak * (billingRate / 60);
  }, 0);
};

export const getActualBillableMins = state => {
  return getPositions(state).reduce((acc, { durationMins }) => {
    return acc + durationMins;
  }, 0);
};

export const getActualBillable = state => {
  return getPositions(state).reduce((acc, { durationMins, billingRate }) => {
    return acc + durationMins * (billingRate / 60);
  }, 0);
};

export const getEmployerMarginTarget = state => {
  const billingRate = new Decimal(getEmployerBillingRate(state));
  const payRate = new Decimal(getEmployerPayRate(state));

  return {
    percentage: billingRate
      .minus(payRate)
      .dividedBy(billingRate)
      .toNumber(),
    dollars: billingRate.minus(payRate).toNumber(),
  };
};

export const getIsJobFilled = state => {
  return getPositionsFilled(state) >= getPeopleNeeded(state);
};

export const getIsSearchingForWorkers = state => {
  const status = getJobStatus(state);
  return (
    !(status === JobStatusesMap.InProgress || status === JobStatusesMap.Completed) &&
    getIsActive(state) &&
    !getIsJobFilled(state)
  );
};

export const getIsAllCheckedIn = state => {
  return getPositions(state).every(({ startShift }) => !!startShift);
};

export const getIsAllCheckedOut = state => {
  return getPositions(state).every(({ endShift }) => !!endShift);
};

export const getIsBonusApplied = state => {
  return getPositions(state).some(({ bonus }) => !!bonus);
};

export const getIsJobEmpty = state => {
  return getPositionsFilled(state) === 0;
};

export const getUpdatedPosition = id => state => {
  return getPositionUpdates(state).find(info => info.id === id) || {};
};

export const getIsMissingShiftInfo = state => {
  const arr = getPositions(state);
  const durationSum = _.sumBy(arr, 'durationMins');
  return durationSum <= 0;
  // return getPositions(state).some(({ durationMins }) => durationMins === 0);
};

export const getHasEditablePosition = state => {
  const positions = getPositions(state);
  return positions.some(({ isEditable }) => isEditable);
};

export const getCanAddWorker = state => {
  return !getIsJobFilled(state);
};

export const getCanRateAll = state => {
  const status = getJobStatus(state);
  return (
    (status === JobStatusesMap.InProgress || status === JobStatusesMap.Completed) &&
    !getIsJobEmpty(state) &&
    getIsAllCheckedIn(state) &&
    getIsAllCheckedOut(state) &&
    getIsActive(state)
  );
};

export const getCanCheckInAll = state => {
  const status = getJobStatus(state);
  const positions = getPositions(state);
  return (
    (status === JobStatusesMap.InProgress || status === JobStatusesMap.Completed) &&
    getIsActive(state) &&
    !getIsJobEmpty(state) &&
    getHasEditablePosition(state) &&
    find(positions, { startShiftUtc: null })
  )
};

export const getCanCheckOutAll = state => {
  const status = getJobStatus(state);
  const positions = getPositions(state);
  const jobDetails = getJobDetails(state);
  return (
    (status === JobStatusesMap.InProgress || status === JobStatusesMap.Completed) &&
    !getIsJobEmpty(state) &&
    getIsAllCheckedIn(state) &&
    getIsActive(state) &&
    getHasEditablePosition(state) &&
    moment().diff(moment(jobDetails.endUtc), "hours") <= 24 &&
    find(positions, { endShiftUtc: null })
  );
};

export const getCanEditRates = state => {
  return !getIsJobEmpty(state) && getHasEditablePosition(state);
};

export const getCanIncreasePositions = state => {
  // return getJobStatus(state) !== JobStatusesMap.Completed && getIsActive(state);
  return getIsActive(state);
};

export const getCanDecreasePositions = state => {
  // return getJobStatus(state) === JobStatusesMap.NotStarted && getIsActive(state);
  return getIsActive(state);
};

// Position Data accessors
export const getPosition = positionId => state => {
  return getPositions(state).find(position => position.id === positionId) || {};
};

export const getIsPositionDeletable = positionId => state => {
  return getPosition(positionId)(state).isDeletable || false;
};

export const getIsPositionEditable = positionId => state => {
  return getPosition(positionId)(state).isEditable || false;
};

export const getIsPositionPayable = positionId => state => {
  return getPosition(positionId)(state).isPayable || false;
};

export const getCanEditPositionRates = positionId => state => {
  return !getIsJobEmpty(state) && getIsPositionEditable(positionId)(state);
};

export const getCanAddWorkerBonus = positionId => state => {
  const status = getJobStatus(state);
  const { startShift, endShift } = getPosition(positionId)(state);
  return (
    (status === JobStatusesMap.InProgress || status === JobStatusesMap.Completed) &&
    startShift &&
    endShift &&
    getIsActive(state) &&
    getIsPositionEditable(positionId)(state)
  );
};

export const getCanRateWorker = positionId => state => {
  const status = getJobStatus(state);
  const { startShift, endShift } = getPosition(positionId)(state);
  return (
    (status === JobStatusesMap.InProgress || status === JobStatusesMap.Completed) &&
    startShift &&
    endShift &&
    getIsActive(state) &&
    getIsBaseJob(state)
  );
};

export const getCanRemovePosition = positionId => state => {
  return getIsPositionDeletable(positionId)(state);
};

export const getCanFlagNoShow = positionId => state => {
  const status = getJobStatus(state);
  const positions = getPositions(state);
  return (
    (status === JobStatusesMap.InProgress || status === JobStatusesMap.Completed) &&
    getIsPositionDeletable(positionId)(state) &&
    getIsActive(state) &&
    find(positions, { endShiftUtc: null, id: positionId })
  );
};

export const getCanPreferWorker = workerId => state => {
  return !getPreferred(state).includes(workerId) && getIsActive(state)
};

export const getCanUnpreferWorker = workerId => state => {
  return getPreferred(state).includes(workerId) && getIsActive(state)
};

export const getCanBanWorker = workerId => state => {
  return !getBanned(state).includes(workerId) && getIsActive(state);
};

export const getCanUnbanWorker = workerId => state => {
  return getBanned(state).includes(workerId) && getIsActive(state);
};


export const getIsFixedTermParentJob = state => {
  return state?.jobDetails?.isFixedTerm && !state?.jobDetails?.referenceJobId
};

export const getIsBaseJob = state => {
  return ((state?.jobDetails?.isFixedTerm && !state?.jobDetails?.referenceJobId) || !state?.jobDetails.isFixedTerm)
};

export const getAllScheduledJobs = state => {
  return state?.jobDetails?.scheduledJobs
};


export const getCanRemoveMultiShiftPosition = positionId => state => {
  return getIsMultiShiftPositionDeletable(positionId)(state);
};

export const getMultiShiftPositionData = state => {
  return state.jobDetails.multiShiftPositions || [];
};

export const getMultiShiftPositionsFilled = state => {
  return state.jobDetails.multiShiftPositionsFilled;
};

export const getIsMultiShiftJobFilled = state => {
  return getMultiShiftPositionsFilled(state) >= getPeopleNeeded(state);
};


export const getIsMultiShiftPositionDeletable = workerId => state => {
  return getMultiShiftPosition(workerId)(state).isDeletable || false;
};

export const getMultiShiftPositions = state => {
  return state.jobDetails.multiShiftPositions || [];
};

export const getMultiShiftPosition = workerId => state => {
  return getMultiShiftPositions(state).find(position => position.workerId === workerId) || {};
};


export const getIsFixedTermJob = state => {
  return state?.jobDetails?.isFixedTerm;
};